// ==========================================================================
// LAYOUT / SITE
// ==========================================================================


//
// Globals
// --------------------------------------------------------------------------
@-webkit-viewport {
	width : device-width; }
	@-moz-viewport {
	width : device-width; }
	@-ms-viewport {
	width : device-width; }
	@-o-viewport {
	width : device-width; }
	@viewport {
	width : device-width; }


	html {
		background: #333;
		-webkit-overflow-scrolling : touch;
		-webkit-tap-highlight-color : lighten( $base-font-color, 75% );
		-webkit-text-size-adjust : 100%;
		-ms-text-size-adjust : 100%;
	}

	body {
		background-color: transparent;
		font-family : $base-font-family;
		line-height : $base-line-height;
		color : $base-font-color;
		-webkit-font-smoothing: antialiased;
	}

	::-moz-selection {
		background-color : lighten( $action-color, 10% );
		color : #333;
		text-shadow : none; }

	::selection {
		background-color : lighten( $action-color, 10% );
		color : #333;
		text-shadow : none; }

	.browserupgrade {
		background: #333;
		padding: $base-spacing;
		text-align: center;
		color: #ccc;

		em {
			font-style: italic;
			color: #fff;
		}

		a {
			color: $action-color;
			font-weight: 700;
			text-decoration: underline;
		}
	}
	//
	// Content Wrappers
	// --------------------------------------------------------------------------
	.wrapper,
	.wrapper--full {
		padding-left: 5%;
		padding-right: 5%;
		width: auto;
		position: relative;
		@include grid-container;

		@include grid-media($grid-sm){
			padding-left: 2%;
			padding-right: 2%;
		}
	}

	.wrapper {
		@include grid-media($grid-lg){
			padding-left: 0;
			padding-right: 0;
			width: 960px;
			margin-left: auto;
			margin-right: auto;
		}

		@include grid-media($grid-xlg){
			width: 1170px;
		}
	}

	.banner-wrapper {
		@include grid-container;
		width: auto;
		position: relative;
		z-index: 5;

		@include grid-media($grid-sm) {
			padding: $base-spacing;
		}


		@include grid-media($grid-lg) {
			padding-left: 0;
			padding-right: 0;
			width: 960px;
			margin-left: auto;
			margin-right: auto;
		}

		@include grid-media($grid-xlg){
			width: 1170px;
		}
	}





	//
	// Site Specifics
	// --------------------------------------------------------------------------
	.page-container {
		background: $off-white;

		p,
		li {
			color: $dark-gray;
		}

		p {
			margin-bottom: $base-spacing;
		}

		@include grid-media($grid-sm){
			p, li { font-size: 110%; }
		  }
		  @include grid-media($grid-md){
			p, li { font-size: 120%; }
		  }
		  @include grid-media($grid-lg){
			p, li { font-size: 140%;}
		  }
		  @include grid-media($grid-xlg){
			p, li { font-size: 150%;}
		  }
	}

	#masthead {
		@include grid-container;
		background: $blue;
		padding-top: $small-spacing;

		.logo {
			width: 140px;
			margin: 0 auto;
			text-align: center;
			//opacity: 0.5;

			// .st0{fill: #fff;}
			// .st1{fill: $dark-blue;}
			// .st2{fill: $dark-blue;}
			// .st3{fill: transparent;}
			// .st4{fill: $dark-blue;}
			// .st5{fill: $blue; }

			.st0{fill: $dark-blue;}
			.st1{fill: $dark-blue;}
			.st2{fill: $dark-blue;}
			.st3{fill: transparent;} // Shading
			.st4{fill: $dark-blue;}
			.st5{fill: $blue; } // Star
		}

		@include grid-media($grid-sm){
			background: rgba(255,255,255,0.95);
			padding-bottom: $small-spacing / 2;


			.logo {
				width: 200px;
				//opacity: 1;
				.st0{fill:#10385D;}
				.st1{fill:#33799F;}
				.st2{fill:#EB1E2D;}
				.st3{fill:#BCC9D9;}
				.st4{fill:#BD2833;}
				.st5{fill:#FFFFFF;}
			}
		}
		@include grid-media($grid-md) {
			padding-top: $base-spacing / 2;
			padding-bottom: $base-spacing / 3 ;

			.logo {
				width: 250px;
				margin: 0;
			}
		}
	}

	#primary-banner {
		@include grid-container;
		background-size: contain;
		position: relative;
		background: $dark-blue;

		// @include grid-media($grid-sm) {
		// 	background-size: contain;
		// }

		@include grid-media($grid-md) {
			background: $dark-blue url("../img/hero-woman-unemployed.jpg") no-repeat left bottom;
			background-size: contain;

		}
	}

	.banner-content {
		padding: 1.5em;
		text-align: center;
		z-index: 10;
		@include linearGradient( rgba(0,0,0,0.3), rgba(0,0,0,0) );


		h1 {
			font-size: $base-font-size * 1.5;
			color: $off-white;
			font-family: $alt-font-family;
			margin-bottom: $small-spacing / 4;
			font-weight: 700;
			text-shadow: 0 1px 0px rgba(0,0,0,0.4);

		}
		h3 {
			font-size: $base-font-size * 1.15;
			font-family: $heading-font-family;
			color: lighten( $blue, 40% );
			font-weight: 400;
			text-shadow: 0 2px 10px rgba(0,0,0,0.2);
		}

		@include grid-media($grid-sm) {
			padding-top: $base-spacing * 2;
			background: transparent;

			h1 { font-size: $base-font-size * 2; }
			h3 { font-size: $base-font-size * 1.25; }
		}

		@include grid-media($grid-md) {
			padding: 0;
			padding-top: $base-spacing * 2;
			text-align: left;
			@include grid-column(6);
			@include grid-shift(-6);

			h1 {
				font-size: $base-font-size * 2.5;
				text-shadow: 0 1px 4px rgba(0,0,0,0.1);
			}
			h3 { font-size: $base-font-size * 1.5;}
		}

		@include grid-media($grid-xlg){
			@include grid-column(7);
			@include grid-shift(-5);
			padding-top: $base-spacing;


			h1 { font-size: $base-font-size * 3; }
			h3 { font-size: $base-font-size * 2; }
		}
	}

	.hero {
		bottom: 0;
		margin-bottom: -6px;
		z-index: 1;

		img {
			width: 60%;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
		&.hero--full {
			display: none;
		}
		@include grid-media($grid-md) {
			display: none;

			&.hero--full {
				display: block;
				width: 40%;
			}
		}

		@include grid-media($grid-lg) {
			&.hero--full {
				width: 40%;
			}
		}
		@include grid-media($grid-xlg) {
			&.hero--full {
				width: 30%;
				max-width: 500px;
			}
		}
	}


	.form-wrapper {
		background: $off-white;
		overflow: hidden;

		header.form-header {
			background: $blue;
			@include linearGradient( $blue, darken($blue, 10%) );
			text-align: center;
			padding: $small-spacing / 2;
			position: relative;
			margin-bottom: $base-spacing;

			h2 {
				color: $off-white;
				font-weight: 700;
				letter-spacing: 0;
				margin-bottom: 0;
				font-size: $base-font-size * 1.4;

				@include grid-media($grid-md){
					font-size: $base-font-size * 1.3;
				}
				@include grid-media($grid-lg){
					font-size: $base-font-size * 1.6;
				}
			}

			&:after {
				border-top: 16px solid darken($blue, 10%);
				border-left: 400px solid transparent;
				border-right: 400px solid transparent;
				bottom: -16px;
				content: "";
				position: absolute;
				left: 50%;
				margin-left: -400px;
				width: 0;
				height: 0;
			}

			@include grid-media($grid-sm){
				//@include linearGradient( $red, darken($red, 10%) );
				padding: $base-spacing;
				padding-bottom: $small-spacing;

				&:after {
					//border-top: 16px solid darken($red, 10%);
				}
			}
		}

		form {
			padding: $base-spacing;
		}

		form {
			header {
				text-align: center;
				margin-bottom: $base-spacing * 1.5;
				p {
					font-size: 80%;
					font-weight: 300;
					line-height: $base-spacing;
				}
			}
		}

		footer {
			padding-top: 0;

			p {
				font-size: 80%;
				font-weight: 300;
				line-height: 1.25em;
				&:last-of-type {
					margin-bottom: 0;
				}

				a {
					color: $blue;
					//border-bottom: 1px dotted $bright-blue;
				}
			}

			.checkbox {
				padding-left: 1.25em;
				position: relative;

				span {
					position: absolute;
					top: 0;
					left: 0;
					float: left;
				}

				[type="checkbox"] {
				  margin: 0;
				  padding: 0;
				  width: 1.15em;
					height: 1.15em;
				}
			}
		}


		@include grid-media($grid-sm) {
			border-radius: 4px;
		}

		@include grid-media($grid-md) {
			@include grid-column(6);
			@include grid-shift(6);
			margin-top: -3em;
		}

		@include grid-media($grid-lg) {
			form {
			header {
				p {
					font-size: 100%;
				}
			}
		}
		}

		@include grid-media($grid-xlg){
			@include grid-column(5);
			@include grid-shift(7);
		}
	}

	.form-group--btn {
		padding-top: $small-spacing;
		text-align: center;
		margin-bottom: $base-spacing;

		.btn {
			font-size: $base-font-size * 1.25;
			color: 90%;
			letter-spacing: 0.04em;

			&:hover {}

			@include grid-media($grid-md){
				font-size: $base-font-size * 1.5;
			}

			@include grid-media($grid-lg){}
		}
	}


	.benefits {
		background: lighten( $blue, 10% );
		padding-top: $base-spacing;
		padding-bottom: $base-spacing;

		header {
			text-align: center;
			padding-top: $small-spacing;
			padding-bottom: $small-spacing;
		}
		h3 {
			font-size: $base-font-size * 1.5;
			font-weight: 700;
			color: $off-white;
			font-family: $alt-font-family;
			margin-bottom: 0.5em;
		}

		p, li {
			font-size: $base-font-size * 1.1;
			font-weight: 500;
			color: $dark-blue;
		}
		ul {
			margin: 0 ($base-spacing) ($base-spacing * 2);
			text-align: left;
		}
		li {
			padding-left: $base-spacing;
			text-align: left;
			position: relative;
			margin-bottom: $small-spacing;
			color: $dark-blue;

			&:before {
				content: "\2713";
				display: block;
				color: lighten( $blue, 30% );
				position: absolute;
				font-weight: 700;
				font-size: $base-font-size * 1.25;
				float: left;
				width: 1em;
				height: 1em;
				margin-left: -$base-spacing;
			}
		}


		@include grid-media($grid-sm) {

			ul {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: flex-start;
				justify-content: flex-start;
				margin-left: $base-spacing;
				margin-right: $base-spacing;
			}
			li {
				flex: 0 0 45%;
				margin-right: 5%;
			}
			p {
				font-weight: 400;
			}
		}


		@include grid-media($grid-md) {
			header {
				padding-top: $base-spacing;
			}
			h3 {
				font-size: $base-font-size * 1.75;
			}
			ul {
				width: 80%;
				margin-left: auto;
				margin-right: auto;
			}

			p, li {
				font-size: $base-font-size * 1.2;
				color: $dark-blue;
			}
			p {
				margin-right: 10%;
				margin-left: 10%;
				font-weight: 400;
			}

		}
		@include grid-media($grid-lg) {

		}
	}

	#benefit-types {
		background: $off-white;

		h3 {
			color: lighten( saturate( $red , 20% ), 10% );
			text-shadow: 0;
		}
		p, li {
			font-weight: 400;
		}
		li {
			color: $off-white;
			&:before {
				//color: $bright-blue;
			}
		}
	}


	#page-footer {
		border-top: solid 1px darken( #eee, 5%);
		padding-top: $base-spacing * 2;
		background: $off-white;
		font-size: $base-font-size * 0.7;
		background: #eee;

		.wrapper {
			text-align: center;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		nav, .copyright {
			flex: 1 auto;
		}

		nav {
			text-align: right;

			li {
				display: inline-block;
				a {
					color: $blue;
					padding: $small-spacing;
				}
			}
		}

		.copyright {
			text-align: left;
			p {
				line-height: 1.25em;
			}
		}

		@include grid-media($grid-md) {
			font-size: $base-font-size * 0.75;
			padding-top: $base-spacing * 3;
		}
	}

	//
	// Offer Path
	// --------------------------------------------------------------------------
	.module {
		background: $off-white;
		box-shadow: 0 8px 14px rgba(0,0,0,0.1);
		border-radius: 4px;

		//	Sections
		header,
		section,
		footer {
			padding-left: $base-spacing;
			padding-right: $base-spacing;
		}
		header {
			padding-top: $base-spacing;
		}
		footer {
			padding-bottom: $base-spacing;
			text-align: center;
		}

		// Typography
		h1 {
			font-weight: 700;
			font-size: 1.85em;
			color: $blue;
			padding-top: $small-spacing;
		}
		h2 {
			font-family: $alt-font-family;
			font-weight: 400;
			//color: $bright-blue;
			font-size: 1.6em;
			padding-top: $small-spacing;

		}
		h3{
			font-weight: 700;
			font-size: 1.35em;
			padding-top: $small-spacing;
			color:$dark-blue;

		}
		h4 {
			font-weight: 700;
			font-size: 1.15em;
			text-transform: uppercase;
			padding-top: $small-spacing;
			color:$red;
		}

		a {
			//color: $bright-blue;
			text-decoration: underline;

			&:hover {
				color: $dark-blue;
			}
		}

		// Media Queries
		@include grid-media($grid-sm){
			header,
			section,
			footer {
				padding-left: $base-spacing * 2;
				padding-right: $base-spacing * 2;
			}
			header {
				padding-top: $base-spacing * 3;
			}

			p, li { font-size: 110%; }
		  }
		  @include grid-media($grid-md){
			p, li { font-size: 120%; }
		  }
		  @include grid-media($grid-lg){

			  h1 {
			font-size: 2.45em;
			}
			h2 {
				font-size: 2.15em;
			}
			h3{
				font-size: 1.65em;
			}
			h4 {
				font-size: 1.45em;
			}

		  }
		  @include grid-media($grid-xlg){
			p, li { font-size: 130%;}
		  }

	}

	// body class
	.offer {
		#primary-banner {
			background: $dark-blue;


			@include grid-media($grid-md) {
				background: $dark-blue url("../../img/banner-home.jpg") no-repeat center bottom;
				background-size: contain;
			}
		}
		.modual {
			@include grid-column(12);
			font-size: 80%;

			@include grid-media($grid-sm) {
				@include grid-column(10);
				@include grid-shift(1);
			}
			@include grid-media($grid-md) {
				@include grid-column(8);
				@include grid-shift(2);
			}
			@include grid-media($grid-lg) {
				@include grid-column(6);
				@include grid-shift(3);
			}
		}
	}

	.path-wrapper {
		@include grid-container;
		padding-top: $base-spacing;
		padding-bottom: $base-spacing;

		@include grid-media($grid-md) {
			padding-top: $base-spacing * 2;
			padding-bottom: $base-spacing * 2;
		}

		@include grid-media($grid-md) {
			padding-top: $base-spacing * 4;
			padding-bottom: $base-spacing * 4;
		}
	}

	.hidden {
		display: none !important;
	}