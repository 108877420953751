ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: 600;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}


